import React from 'react';
import { Box, Typography } from '@mui/material';

const History = () => {
  return (
    <Box sx={{
       backgroundColor: '#f5f5f5',
        padding: '20px', width: '100%', marginTop: '40px' }}>
      {/* Title: Journey of Dr. Muhammad Sadiq */}
      <Typography variant="h1" sx={{ fontFamily: 'Poppins', fontSize: { xs: '24px', sm: '32px', md: '40px' }, fontWeight: 'bold', textAlign: 'center', color: '#333', marginBottom: '40px' }}>
        The Journey of Dr. Muhammad Sadiq
      </Typography>

      {/* Early Life & Education Section */}
      <Box sx={{
         display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
           alignItems: 'stretch',
            marginBottom: '40px'
             }}>
        <Box sx={{
           flex: 1,
            mb: { xs: '20px', md: 0 },
             display: 'flex',
              flexDirection: 'column',
               justifyContent: 'space-between'
                }}>
          <Typography variant="h2" sx={{
             fontFamily: 'Poppins',
              fontSize: { xs: '20px', sm: '28px', md: '36px' },
               fontWeight: 'bold',
                lineHeight: '40px',
                 color: '#333'
                  }}>
            Early Life & Education
          </Typography>
          <Typography variant="h2" sx={{ 
            fontFamily: 'Poppins', 
            fontSize: { xs: '18px', sm: '24px', md: '30px' },
             fontWeight: '500',
              lineHeight: '60px',
               color: '#333'
                }}>
            Birth & Family Background
          </Typography>
          <Typography sx={{
             fontFamily: 'Poppins',
              fontSize: { xs: '14px', sm: '16px', md: '18px' },
               fontWeight: '500',
                lineHeight: { xs: '30px', md: '34px' },
                 color: '#333',
                  textAlign: 'justify',
                  marginBottom: '40px' 
                   }}>
            Dr. Muhammad Sadiq was born on July 11, 1950, in Khanewal, Punjab, Pakistan, into a family of five children. His rural upbringing shaped his values of empathy, resilience, and dedication.
          </Typography>
          <Typography variant="h2" sx={{
             fontFamily: 'Poppins',
              fontSize: { xs: '18px', sm: '24px', md: '30px' },
               fontWeight: '500',
                 color: '#333',
                  marginBottom: '10px' 
                   }}>
            Primary & Secondary Education
          </Typography>
          <Typography sx={{
             fontFamily: 'Poppins',
              fontSize: { xs: '14px', sm: '16px', md: '18px' },
               fontWeight: '500',
                lineHeight: { xs: '30px', md: '34px' },
                 color: '#333',
                  textAlign: 'justify',
                  marginBottom: '40px' 
                   
                  }}>
            He attended Government High School in Nanakpur, Khanewal, completing his early schooling before moving on to Government Islamia High School, Khanewal, for his matriculation.
          </Typography>
          <Typography variant="h2" sx={{ fontFamily: 'Poppins', fontSize: { xs: '18px', sm: '24px', md: '30px' }, fontWeight: '500', lineHeight: '60px', color: '#333' }}>
            Academic Pursuits
          </Typography>
          <Typography sx={{
             fontFamily: 'Poppins',
              fontSize: { xs: '14px', sm: '16px', md: '18px' },
               fontWeight: '500',
                lineHeight: { xs: '30px', md: '34px' },
                 color: '#333',
                  textAlign: 'justify'
                   }}>
            Driven by a passion for healthcare, Dr. Sadiq completed his F.Sc. at Emerson University, Multan, scoring 623 out of 900. Although he aspired to study medicine, narrowly missing the MBBS entrance by seven marks, he chose veterinary sciences instead. He graduated in 1974 with a Doctor of Veterinary Medicine (DVM) degree from the University of Agriculture, Faisalabad, embarking on a career that would define the poultry industry in Pakistan.
          </Typography>
        </Box>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'stretch' }}>
          <img src='/DrSahbh1.jpg' alt="Dr. Muhammad Sadiq" style={{ width: '90%', height: '100%', objectFit: 'contain' }} />
        </Box>
      </Box>

      {/* Visionary Career in Poultry and Leadership */}
        <Box sx={{ backgroundColor: '#027D40', padding: '40px', alignItems: 'stretch', marginBottom: '80px' }}>
          <Typography variant="h1" sx={{ fontFamily: 'Poppins', fontSize: { xs: '24px', sm: '32px', md: '40px' }, fontWeight: 'bold', textAlign: 'center', color: '#FFFFFF', marginBottom: '40px' }}>
            Visionary Career in Poultry and Leadership
          </Typography>

          {/* Pioneering a Poultry Empire */}
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row-reverse' }, alignItems: 'stretch', marginBottom: '80px' }}>
            <Box sx={{ flex: 1, mb: { xs: '20px', md: 0 }, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Typography variant="h2" sx={{ fontFamily: 'Poppins', fontSize: { xs: '20px', sm: '28px', md: '36px' }, fontWeight: '500', lineHeight: '80px', color: '#FFFFFF' }}>
            Pioneering a Poultry Empire
          </Typography>
          <Typography sx={{ fontFamily: 'Poppins', fontSize: { xs: '14px', sm: '16px', md: '18px' }, fontWeight: '500', lineHeight: { xs: '30px', md: '54px' }, color: '#FFFFFF', textAlign: 'justify' }}>
            Dr. Sadiq established his first poultry farm in 1975, which became the foundation for Sadiq Poultry (Private) Limited, officially incorporated on September 17, 2004. As founder, chairman, and CEO of the Sadiq Group of Companies, headquartered in Rawalpindi, he transformed the business into an industry leader, currently employing over 5,700 people.
          </Typography>
            </Box>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'stretch' }}>
          <img src='/hist1.png' alt="Dr. Muhammad Sadiq" style={{ width: '90%', height: '100%', objectFit: 'cover', borderRadius: '8px' }} />
            </Box>
          </Box>

          {/* Global Presence & Industry Influence */}
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'stretch', marginBottom: '80px' }}>
            <Box sx={{ flex: 1, mb: { xs: '20px', md: 0 }, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Typography variant="h2" sx={{ fontFamily: 'Poppins', fontSize: { xs: '20px', sm: '28px', md: '36px' }, fontWeight: '500', lineHeight: '80px', color: '#FFFFFF' }}>
            Global Presence & Industry Influence
          </Typography>
          <Typography sx={{ fontFamily: 'Poppins', fontSize: { xs: '14px', sm: '16px', md: '18px' }, fontWeight: '500', lineHeight: { xs: '30px', md: '54px' }, color: '#FFFFFF', textAlign: 'justify' }}>
            Dr. Sadiq was known for his extensive travels and participation in international seminars, demonstrations, and exhibitions related to the poultry, feed milling, and pharmaceutical industries. His expertise and international outlook greatly influenced Pakistan's poultry industry.
          </Typography>
            </Box>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'stretch' }}>
          <img src='/hist2.png' alt="Dr. Muhammad Sadiq" style={{ width: '90%', height: '100%', objectFit: 'cover', borderRadius: '8px' }} />
            </Box>
          </Box>

          {/* Recognized Industry Leader */}
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row-reverse' }, alignItems: 'stretch', marginBottom: '80px' }}>
            <Box sx={{ flex: 1, mb: { xs: '20px', md: 0 }, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Typography variant="h2" sx={{ fontFamily: 'Poppins', fontSize: { xs: '20px', sm: '28px', md: '36px' }, fontWeight: '500', lineHeight: '80px', color: '#FFFFFF' }}>
            Recognized Industry Leader
          </Typography>
          <Typography sx={{ fontFamily: 'Poppins', fontSize: { xs: '14px', sm: '16px', md: '18px' }, fontWeight: '500', lineHeight: { xs: '30px', md: '54px' }, color: '#FFFFFF', textAlign: 'justify' }}>
            He served as the Central Chairman of the Pakistan Feeds Association and the President of the World’s Poultry Science Association (Pakistan Branch) for over 25 years. His leadership extended to the feeds industry, where he was awarded the title of "Top Man in Feeds Industry of Pakistan" by the American Soybean Meal Association, highlighting his contributions to feed science.
          </Typography>
            </Box>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'stretch' }}>
          <img src='/hist3.png' alt="Dr. Muhammad Sadiq" style={{ width: '90%', height: '100%', objectFit: 'cover', borderRadius: '8px' }} />
            </Box>
          </Box>

          {/* National & International Outreach */}
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'stretch', marginBottom: '80px' }}>
            <Box sx={{ flex: 1, mb: { xs: '20px', md: 0 }, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Typography variant="h2" sx={{ fontFamily: 'Poppins', fontSize: { xs: '20px', sm: '28px', md: '36px' }, fontWeight: '500', lineHeight: '80px', color: '#FFFFFF' }}>
            National & International Outreach
          </Typography>
          <Typography sx={{ fontFamily: 'Poppins', fontSize: { xs: '14px', sm: '16px', md: '18px' }, fontWeight: '500', lineHeight: { xs: '30px', md: '54px' }, color: '#FFFFFF', textAlign: 'justify' }}>
            Dr. Sadiq’s commitment to industry education led him to establish poultry science clubs across 12 Pakistani universities, design an extensive outreach program, and host three international conferences and numerous training sessions. His focus on knowledge-sharing continues to uplift standards within the industry.
          </Typography>
            </Box>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'stretch' }}>
          <img src='/hist4.png' alt="Dr. Muhammad Sadiq" style={{ width: '90%', height: '100%', objectFit: 'cover', borderRadius: '8px' }} />
            </Box>
          </Box>
        </Box>
        <Box sx={{ backgroundColor: '#FFFFFF', padding: '40px', alignItems: 'stretch', marginBottom: '0px' }}>
          <Typography variant="h1" sx={{ fontFamily: 'Poppins', fontSize: { xs: '24px', sm: '32px', md: '40px' }, fontWeight: 'bold', textAlign: 'center', color: '#333', marginBottom: '40px' }}>
            Philanthropy and the Dr. Sadiq Foundation
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: { xs: 'column', md: 'column' }, alignItems: 'stretch', marginBottom: '40px' }}>
            <Box sx={{ flex: 1, mb: { xs: '20px', md: 0 }, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Typography variant="h2" sx={{ display: 'flex', justifyContent: 'center', fontSize: { xs: '20px', sm: '28px', md: '36px' }, fontWeight: '500', lineHeight: '60px', color: '#333' }}>
            Humanitarian Contributions
          </Typography>
          <Typography sx={{ fontFamily: 'Poppins', fontSize: { xs: '14px', sm: '16px', md: '18px' }, fontWeight: '500', lineHeight: { xs: '30px', md: '54px' }, color: '#333', textAlign: 'justify' }}>
            Driven by compassion, Dr. Sadiq founded the Dr. Sadiq Foundation (DSF) to support healthcare, education, and financial assistance for orphans, widows, and the disabled. Through DSF, he regularly funded scholarships for students, particularly those pursuing careers in veterinary and poultry sciences, fostering a new generation of skilled professionals.
          </Typography>
            </Box>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <img src='/hbed.jpg' alt="Dr. Muhammad Sadiq" style={{ width: '90%', height: '80%', objectFit: 'cover', borderRadius: '8px' }} />
            </Box>
            <Typography variant="h2" sx={{ display: 'flex', justifyContent: 'center', fontSize: { xs: '20px', sm: '28px', md: '36px' }, fontWeight: '500', lineHeight: '60px', marginTop: '10px', color: '#333' }}>
          Vision for a Hospital
            </Typography>
            <Typography sx={{ fontFamily: 'Poppins', fontSize: { xs: '14px', sm: '16px', md: '18px' }, fontWeight: '500', lineHeight: { xs: '30px', md: '54px' }, color: '#333', textAlign: 'justify', padding: '10px 100px' }}>
          His vision extended further with plans for the Dr. Sadiq Foundation Hospital, a project aimed at providing quality healthcare services to underserved communities, embodying his lifelong commitment to uplifting those in need.
            </Typography>
          </Box>
        </Box>
      <Box sx={{ backgroundColor: '#FFFFFF', padding: '20px', alignItems: 'stretch', marginBottom: '0px' }}>
        <Typography variant="h1" sx={{ fontFamily: 'Poppins', fontSize: { xs: '24px', sm: '32px', md: '40px' }, fontWeight: 'bold', textAlign: 'center', color: '#333', marginBottom: '40px' }}>
          Legacy and Final Remembrance
        </Typography>
        <Box sx={{
           display: 'flex',
            justifyContent: 'center', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'center', 
            marginBottom: '0px' }}>
          <Box sx={{ flex: 1, mb: { xs: '20px', md: 0 }, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Typography variant="h2" sx={{ fontFamily: 'Poppins', fontSize: { xs: '20px', sm: '28px', md: '36px' }, fontWeight: '500', lineHeight: '60px', color: '#333' }}>
              Passing and Public Mourning
            </Typography>
            <Typography sx={{ fontFamily: 'Poppins', fontSize: { xs: '14px', sm: '16px', md: '18px' }, fontWeight: '500', lineHeight: { xs: '30px', md: '34px' }, color: '#333', textAlign: 'justify' }}>
              On October 23, 2020, Dr. Muhammad Sadiq passed away, leaving an enduring legacy in the poultry industry and philanthropy. Thousands gathered to honor him at his burial in the H-11 Graveyard in Islamabad, Pakistan, paying tribute to a life of dedication and achievement.
            </Typography>
            <Typography variant="h1" sx={{
               fontFamily: 'Poppins', 
               fontSize: { xs: '20px', sm: '28px', md: '36px' }, 
               fontWeight: '500', 
               color: '#333',
               marginTop: '40px',
               marginBottom: '20px'
               }}>
              A Legacy That Lives On
            </Typography>
            <Typography sx={{ 
              fontFamily: 'Poppins', 
              fontSize: { xs: '14px', sm: '16px', md: '18px' }, 
              fontWeight: '500', 
              lineHeight: { xs: '30px', md: '34px' }, 
              color: '#333', 
              textAlign: 'justify'
               }}>
              Dr. Sadiq’s contributions continue to inspire the industry and enrich communities. He is remembered for his dedication, innovative vision, and significant impact on poultry science and humanitarian efforts in Pakistan. May Almighty Allah grant him the highest rank in Jannah, and may his legacy serve as a guiding light for future generations.
            </Typography>
          </Box>
          <Box sx={{ 
            flex: 1, 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center'
             }}>
            <img src='/DrSahbh4.jpeg'
           alt="Dr. Muhammad Sadiq"
            style={{ 
            width: '70%',
             height: '50%',
              objectFit: 'cover' }} />
        </Box>
      </Box>
      </Box>
    </Box>
  );
};

export default History;
